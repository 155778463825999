import React, { useEffect } from "react";
import { Hook, IMutator } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../../constants/EnvironmentConfiguration";
import { EnhancedCustomer } from "../../../../dtos/Customer";
import { EnhancedToken } from "../../../../dtos/Token";
import LoadingExpander from "../../../App/Components/LoadingExpander";
import SupportedFlows from "../../../App/Compositions/SupportedFlows";
import {
  IForbiddenSessionViewRouter,
  IInvalidLinkViewRouter,
  ISomethingWentWrongRouter
} from "../../../Routing/Types/IRouter";
import { ResponseDefaultCenter } from "../../../Types/ICenterResponse";
import ISession, { Session } from "../../../Types/ISession";
import { TokenResponse } from "../Types/TokenRequest";

/** A session constructed from the initial authorization response at the start of the application flow. */
export class StartupSession extends Session {
  constructor(tokenResponse: TokenResponse, sessionKey: string) {
    const flowToCamelCase = `${tokenResponse.flow.name[0].toLowerCase()}${tokenResponse.flow.name.substring(1)}`;
    if (!SupportedFlows.some(f => f.name === flowToCamelCase)) throw "The specified flow is not supported.";
    super(
      new EnhancedCustomer(tokenResponse.customer),
      new ResponseDefaultCenter(tokenResponse.center),
      sessionKey,
      flowToCamelCase,
      false,
      tokenResponse.leadId,
      tokenResponse.flow.id,
      new EnhancedToken(tokenResponse.token),
      EnvironmentConfiguration.version,
      null,
      null,
      null
    );
  }
}

export enum UsePostTokenErrorType {
  NotFound,
  AlreadyCompleted,
  SomethingWentWrong
}

export interface ILoadSessionViewRouterProps {
  /** The key which uniquely identifies the session to the server. */
  readonly sessionKey: string;
}

export interface IUsePostTokenError {
  /** The error type which prevented the hook from succeeding. */
  readonly type: UsePostTokenErrorType;
  /** The optional message associated with the error. */
  readonly message?: string;
}

export interface ILoadSessionViewProps extends IMutator<ISession>, ILoadSessionViewRouterProps {
  /** The router which provides the routes for terminal and success scenarios. */
  router: IForbiddenSessionViewRouter & IInvalidLinkViewRouter & ISomethingWentWrongRouter;
}

export interface ILoadSessionViewConfig {
  /** The hook which obtains the token for the given session key. */
  usePostToken: Hook<TokenResponse, IUsePostTokenError, string>;
}

/** The temporary loading screen shown when loading a session from its key.
 * @remarks This page is most likely executed so quickly that the user won't often see it.
 */
const LoadSessionView: React.FC<ILoadSessionViewProps & ILoadSessionViewConfig> = ({
  router,
  sessionKey,
  usePostToken,
  onChange
}) => {
  useEffect(() => localStorage.clear(), []);

  const { result, error } = usePostToken(sessionKey);

  useEffect(() => {
    if (result) onChange(new StartupSession(result, location.pathname.substring(1)));
  }, [result]);

  useEffect(() => {
    if (!error) return;
    if (error.type === UsePostTokenErrorType.AlreadyCompleted)
      router.goToForbiddenSessionView({ forbiddenSessionErrorMessage: error.message });
    else if (error.type === UsePostTokenErrorType.NotFound) router.goToInvalidLinkView();
    else router.goToSomethingWentWrongView();
  }, [error]);

  return <LoadingExpander />;
};

export default LoadSessionView;
