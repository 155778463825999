import { useCallback, useEffect, useRef, useState } from "react";
import { Nullable, TriggeredHook } from "sonobello.utilities.react";

import { HttpStatusCode } from "../../../../types/HttpStatusCodes";
import useObx from "../../../../utils/UseObx";
import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import { OpsScheduleSlot } from "../../../Calendar/Types/OpsCalendar";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import IReservation from "../../../Types/IReservation";
import OpsReservation from "../../../Types/OpsReservation";
import { IUseCreateOpsReservationProps } from "./UseCreateHybridReservation";
import { ReservationRequest } from "./UseCreateLegacyReservation";

/** Handles the network requests necessary to make reservation requests for the OPS calendar process. */
const useCreateOpsReservation: TriggeredHook<
  IReservation,
  OpsScheduleSlot,
  boolean,
  IUseCreateOpsReservationProps
> = props => {
  const propsRef = useRef(props);
  const [result, setResult] = useState<Nullable<IReservation>>(null);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const { res, err, loading, setReq } = useObx<
    void,
    ReservationRequest,
    { service: ICalendarService; slot: OpsScheduleSlot }
  >("Post Reservation", { method: "post" });

  useEffect(() => {
    if (res?.code !== HttpStatusCode.Accepted) return;
    setResult(new OpsReservation(propsRef.current.center, res.request.custom!.service, res.request.custom!.slot));
  }, [res]);

  const execute = useCallback(
    (slotToReserve: OpsScheduleSlot) =>
      setReq(
        r =>
          r && {
            ...r,
            url: ApiRequestPaths.postOpsReservationUrl(
              propsRef.current.leadId,
              propsRef.current.customerId,
              propsRef.current.center.id,
              propsRef.current.service.id,
              slotToReserve.id
            ),
            payload: new ReservationRequest(slotToReserve),
            custom: {
              service: propsRef.current.service,
              slot: slotToReserve
            }
          }
      ),
    []
  );

  return {
    result,
    isLoading: loading,
    error: Boolean(err && err.error.response?.status !== HttpStatusCode.Unauthorized),
    execute
  };
};

export default useCreateOpsReservation;
