import React, { useEffect } from "react";
import { IComponentParent, TriggeredHook } from "sonobello.utilities.react";

import { AppContextProps } from "../../../../AppContext";
import { IThankYouViewProps } from "../../../../components/views/thankYou/ThankYouView";
import { CustomerNote } from "../../../../dtos/CustomerNote";
import { LeadResult } from "../../../../dtos/LeadResult";
import { ISomethingWentWrongRouter, IThankYouViewRouter } from "../../../Routing/Types/IRouter";
import IReservation from "../../../Types/IReservation";
import StepWrapper, { IStepWrapperProps } from "../../Components/StepWrapper";
import { IUseGetCalendarEventRequest } from "../Hooks/UseGetCalendarEventUrl";

/** The expected result of the {@link IConfirmationStepWrapperProps.onConfirmedAppointment} hook. */
export interface IConfirmedAppointmentResponse {
  appointment: IThankYouViewProps["appointment"] & { id: string };
  leadResult: LeadResult;
}

export interface IConfirmationStepWrapperProps {
  /** @see {@link AppContextProps.customerCState} */
  customer: AppContextProps["customerCState"];
  /** @see {@link AppContextProps.leadIdCState} */
  leadId: AppContextProps["leadIdCState"];
  /** @see {@link AppContextProps.medicalFormCState} */
  medicalForm: AppContextProps["medicalFormCState"];
  /** The booking reservation to be confirmed. */
  reservation: IReservation;
  /** Dispatches the updated customer note information when the appointment is confirmed. */
  onConfirmedAppointment: (customerNoteText: string) => void;
}

export interface IConfirmationStepWrapperConfig
  extends IComponentParent,
    Pick<IStepWrapperProps, "NextButtonDesktop" | "NextButtonMobile"> {
  /** The view router for the 'Thank You' and 'Something Went Wrong' views. */
  router: IThankYouViewRouter & ISomethingWentWrongRouter;
  /** Dispatches the confirmed appointment details */
  useConfirmAppointment: TriggeredHook<IConfirmedAppointmentResponse, IReservation>;
  /** Obtains the calendar event url for the given appointment. */
  useGetCalendarEventUrl: TriggeredHook<string, IUseGetCalendarEventRequest>;
}

/** A step wrapper which on 'next step' clicked confirms the reservation as an appointment and obtains the calendar
 * event url before sending the user to the 'Thank You' view.
 */
const ConfirmationStepWrapper: React.FC<IConfirmationStepWrapperProps & IConfirmationStepWrapperConfig> = ({
  children,
  customer,
  leadId,
  medicalForm,
  reservation,
  router,
  onConfirmedAppointment,
  useConfirmAppointment,
  useGetCalendarEventUrl,
  NextButtonDesktop,
  NextButtonMobile
}) => {
  const {
    result: confirmedAppointment,
    execute: confirmAppointment,
    error: confirmAppointmentError,
    isLoading: isAppointmentLoading
  } = useConfirmAppointment();

  const {
    result: calendarEventUrl,
    execute: getCalendarEventUrl,
    isLoading: isCalendarEventUrlLoading,
    error: calendarEventUrlError
  } = useGetCalendarEventUrl();

  useEffect(() => {
    if (confirmedAppointment && (calendarEventUrl || calendarEventUrlError))
      router.goToThankYouView(
        new IThankYouViewProps(reservation.center, confirmedAppointment.appointment, calendarEventUrl || null)
      );
  }, [confirmedAppointment, calendarEventUrlError, calendarEventUrl, router]);

  useEffect(() => {
    if (confirmAppointmentError) router.goToSomethingWentWrongView();
  }, [confirmAppointmentError]);

  useEffect(() => {
    if (confirmedAppointment) {
      onConfirmedAppointment(
        CustomerNote.getText({
          customer,
          center: reservation.center,
          disqualifyingReasons: undefined,
          service: reservation.service,
          medicalForm,
          leadResult: confirmedAppointment.leadResult
        })
      );
      getCalendarEventUrl({ customerId: customer.id, leadId, appointmentId: confirmedAppointment.appointment.id });
    }
  }, [confirmedAppointment]);

  return (
    <StepWrapper
      nextButtonLabel="Book This Appointment"
      nextButtonLoading={isAppointmentLoading || isCalendarEventUrlLoading}
      onNext={() => confirmAppointment(reservation)}
      NextButtonDesktop={NextButtonDesktop}
      NextButtonMobile={NextButtonMobile}
    >
      {children}
    </StepWrapper>
  );
};

export default ConfirmationStepWrapper;
